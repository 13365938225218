// The first language in the list is used in some instances as a default
const SupportedLanguages = ['en', 'es', 'es-MX', 'fr-CA', 'fr', 'ja', 'pt-BR', 'de', 'it', 'nl'];

export default SupportedLanguages;

export const LanguageMap = {
  en: 'en-US',
  es: 'es-ES',
  'es-mx': 'es-MX',
};
